import { stringify } from 'qs'
import TokenSerializer from '~/serializer/Token'
import UserSerializer from '~/serializer/User'

interface SuccessResult {
  success: true
  result: true
}

export default {
  async login(
    { username, code, accessToken, type }:
    { username?: string; code?: string; accessToken: string; type?: string },
  ) {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    const { data } = await $axios.post<{ result: { user: { FAVORITE_PRODUCTS: { [key: string]: number } } } }>(
      '/api/v2/oauth/token',
      stringify({
        grant_type: type,
        username,
        confirm_code: code,
        access_token: accessToken,
      }),
      {
        params: {
          isTokenPath: true,
        },
        baseURL: runtimeConfig.public.authUrl,
      },
    )

    const favoriteList: { [key: string]: boolean } = {}
    Object.values(data.result?.user?.FAVORITE_PRODUCTS || {}).forEach(el => favoriteList[el] = true)
    const favorite: { [key: string]: boolean } = favoriteList

    return {
      token: TokenSerializer(data.result),
      user: UserSerializer(data.result?.user),
      favorite,
    }
  },
  /**
   * Специальный способ авторизации через токен, который шарится из вью-стора,
   * благодарю этому пользователю не нужно повторно авторизоваться в ДС
   */
  async loginByAccessToken(
    { clientID, clientSecret, accessToken }: { clientID: string; clientSecret: string; accessToken: string },
  ) {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    const { data } = await $axios.post<{ result: { user: { FAVORITE_PRODUCTS: { [key: string]: number } } } }>(
      '/api/v2/oauth/token',
      stringify({
        grant_type: 'access_token',
        client_id: clientID,
        client_secret: clientSecret,
        access_token: accessToken,
      }),
      {
        params: {
          isTokenPath: true,
        },
        baseURL: runtimeConfig.public.authUrl,
      },
    )

    const favoriteList: { [key: string]: boolean } = {}
    Object.values(data.result?.user?.FAVORITE_PRODUCTS || {}).forEach(el => favoriteList[el] = true)
    const favorite: { [key: string]: boolean } = favoriteList

    return {
      result: {
        token: TokenSerializer(data?.result),
        user: UserSerializer(data?.result?.user),
        favorite,
      },
    }
  },
  async sendConfirmCode({ phone, short, recaptchaToken }: { phone: string; short: boolean; recaptchaToken?: string }): Promise<SuccessResult> {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    const { data } = await $axios.get(
      `/api/v1/oauth/code/send/${phone}?${stringify({
        short,
        token: recaptchaToken,
      })}`,
      {
        baseURL: runtimeConfig.public.authUrl,
      },
    )
    return data
  },
  async checkConfirmCode({ code }: { code: string }): Promise<SuccessResult> {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    const { data } = await $axios.get(`/api/v1/register/code/check/${code}`, {
      baseURL: runtimeConfig.public.authUrl,
    })
    return data
  },
  async sendForgetConfirmationCode({ login }: { login: string }): Promise<SuccessResult> {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    const { data } = await $axios.get(`/api/v2/user/recover/send/${login}`, {
      baseURL: runtimeConfig.public.authUrl,
    })
    return data
  },
  async sendVerificationEmail({ email }: { email: string }): Promise<SuccessResult> {
    const { $axios } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    const { data } = await $axios.get(`/api/v2/user/verify/email/${email}`, {
      baseURL: runtimeConfig.public.authUrl,
    })
    return data
  },
  async checkCodeAndLogin(
    { code, type, accessToken }: { code: string; type: string; accessToken: string },
  ) {
    const { $api } = useNuxtApp()
    await $api.auth.checkConfirmCode({ code })
    const response = await $api.auth.login({ code, type, accessToken })

    return response
  },
}
