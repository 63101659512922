import Thumbnail from './Thumbnail'
import ProductStatus from './product/ProductStatus'
import type { Product } from '~/type/product/Product'

export default function (el: { [key: string]: any } = {}): Product {
  return {
    id: el.ID,
    name: el.NAME,
    path: el.DETAIL_PAGE_PATH,
    thumbnail: Thumbnail(el.THUMBNAILS),
    quantity: el.QUANTITY,
    amount: el?.AMOUNT,
    inFavorites: el.IN_FAVORITES,
    // TODO пока не будут возвращать поле с бэка
    isUnavailable: el.QUANTITY === 0,
    cart: el.IN_BASKET || false,
    isGift: false,
    weight: {
      expected: el.WEIGHT?.EXPECTED,
      shipped: el.WEIGHT?.SHIPPED,
    },
    status: el.STATUS ? ProductStatus(el.STATUS) : {},
    price: {
      common: {
        value: el.WEIGHT.SHIPPED_WEIGHT_PRICE
          ? Math.round(el.WEIGHT.SHIPPED_WEIGHT_PRICE)
          : (el.DISCOUNT ? el.PRICE - el.DISCOUNT : el.PRICE),
      },
    },
    category: el.SECTION?.NAME,
    changedAmount: el.PREVIOUS_AMOUNT,
  }
}
