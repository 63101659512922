<script setup lang="ts">
import { formatPrice } from '@artmizu/utils'
import { useServiceStore } from '~/store/service'
import type { Order } from '~/type/order/Order'

const props = defineProps<{
  order: Order
}>()

const emit = defineEmits<{ (e: 'detail-click'): void }>()

const serviceStore = useServiceStore()

const description = computed(() => {
  if (props.order.status.type === 'cancelled' && props.order.payResult)
    return props.order.payResult
  else
    return `№${props.order.number} на сумму ${formatPrice(props.order.price!.common!.value)}₽`
})

const title = computed(() => {
  if (props.order.isChanged)
    return 'В заказе есть изменения'
  else
    return props.order.status.type === 'delayed' ? 'Отложенная доставка' : props.order.status.name
})

const subtitle = computed(() => {
  if (props.order.isChanged) {
    return `Заказ №${props.order.id} ${props.order.status.name.toLowerCase()}`
  }
  else {
    const currentDay = Number(serviceStore.currentDate?.split(' ')[0].split('/')[1])
    const deliveryDay = Number((props.order.delivery.date?.split(' ')[0].split('-')[2]))
    const dayText = currentDay === deliveryDay ? '' : 'завтра'

    return props.order.status.type === 'delayed'
      ? `Доставим ${dayText} c ${props.order.delivery.date?.split(' ')[1].split('-')[0].slice(0, -3)} до ${props.order.delivery.date?.split(' ')[1].split('-')[1].slice(0, -3)}`
      : description.value
  }
})
</script>

<template>
  <div class="order-status" :data-e2e="order.id">
    <div class="order-status__content-wrap">
      <div :class="`order-status__img-wrap order-status__img-wrap_${order.status.type}`">
        <div class="order-status__img" />
      </div>
      <div class="order-status__text">
        <div v-if="order.price?.common?.value" class="order-status__subtitle">
          {{ subtitle }}
        </div>
        <div class="order-status__title">
          {{ title }}
        </div>
      </div>
    </div>
    <div
      class="order-status__details-button"
      @click="emit('detail-click')"
    >
      <div class="order-status__details-text">
        Детали заказа
      </div>
      <div class="order-status__details-dot" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.order-status {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-width: 400px;
  min-height: 100px;
  padding: 18px;
  user-select: none;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;

  &__content-wrap {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  &__img-wrap {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background-color: #ffc531;
    border-radius: 50%;

    &_delayed {
      background-color: #007AFF;

      .order-status__img {
        width: 26px;
        height: 26px;
        background-image: svg-load('./asset/delivery.svg', fill=transparent);
      }
    }

    &_accepted {

      ^^&__img {
        width: 17px;
        height: 11px;
        background-image: svg-load('./asset/order-accepted-icon.svg', fill=transparent);
      }
    }

    &_collect {

      ^^&__img {
        width: 26px;
        height: 26px;
        background-image: svg-load('./asset/order-cart-icon.svg', fill=transparent);
      }
    }

    &_transit {

      ^^&__img {
        width: 29px;
        height: 29px;
        background-image: svg-load('./asset/order-transit-icon.svg', fill=transparent);
      }
    }

    &_completed {

      ^^&__img {
        width: 27px;
        height: 27px;
        background-image: svg-load('./asset/order-completed-icon.svg', fill=transparent);
      }
    }

    &_not-paid {
      background-color: #e30613;

      ^^&__img {
        width: 25px;
        height: 25px;
        background-image: svg-load('./asset/order-not-paid-icon.svg', fill=transparent);
      }
    }

    &_cancelled {
      background-color: #e30613;

      ^^&__img {
        width: 20px;
        height: 20px;
        background-image: svg-load('./asset/order-cancelled-icon.svg', fill=transparent);
      }
    }
  }

  &__img {
    background-repeat: no-repeat;
    background-position: center;
  }

  &__details-button {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #e5e5e5;
    border-radius: 50%;
  }

  &__details-text {
    display: none;
  }

  &__details-dot {
    position: relative;
    width: 5px;
    height: 5px;
    background-color: #000;
    border-radius: 50%;

    &:after,
    &:before {
      position: absolute;
      width: 5px;
      height: 5px;
      content: '';
      background-color: #000;
      border-radius: 50%;
    }

    &:after {
      left: 9px;
    }

    &:before {
      right: 9px;
    }
  }

  &__title {
    max-height: 50px;
    overflow: hidden;
    font-size: 21px;
    font-weight: 500;
    line-height: 24px;
    text-overflow: ellipsis;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 16px;
    color: #9b9b9b;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
    min-width: auto;
    padding: 15px 15px 0;

    &__content-wrap {
      margin-bottom: 15px;
    }

    &__title {
      font-size: 16px;
      line-height: 18px;
    }

    &__img-wrap {
      width: 40px;
      height: 40px;

      &_accepted {

        ^^&__img {
          width: 24px;
          height: 24px;
        }
      }

      &_collect {

        ^^&__img {
          width: 21px;
          height: 21px;
        }
      }

      &_transit {

        ^^&__img {
          width: 29px;
          height: 29px;
        }
      }

      &_completed {

        ^^&__img {
          width: 21px;
          height: 21px;
        }
      }

      &_not-paid {

        ^^&__img {
          width: 25px;
          height: 25px;
        }
      }

      &_cancelled {

        ^^&__img {
          width: 16px;
          height: 16px;
        }
      }
    }

    &__img {
      background-size: contain;
    }

    &__details-button {
      width: calc(100% + 30px);
      height: auto;
      padding: 10px 0;
      margin: 0 -15px;
      border: 0;
      border-top: 1px solid #e5e5e5;
      border-radius: 0;
    }

    &__details-dot {
      display: none;
    }

    &__details-text {
      display: block;
    }
  }
}
</style>
