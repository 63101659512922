export default {
  async getPolicy() {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.get('/private-policy')

    return data
  },
  async getAgreement() {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.get('/user-agreement')

    return data
  },
}
