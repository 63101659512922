<script setup lang="ts">
const props = defineProps<{
  show: boolean
  title: string
}>()
const emit = defineEmits<{
  (e: 'close'): void
}>()

watch(() => props.show, (val) => {
  if (val) {
    document.body.classList.add('delivery-time-modal-overflow-body')
  }
  else {
    if (document.body.classList.contains('delivery-time-modal-overflow-body'))
      document.body.classList.remove('delivery-time-modal-overflow-body')
  }
})

function onOverlayClick(e: Event) {
  if (e.target instanceof HTMLElement) {
    const isOverlay = e.target.classList.contains('delivery-time-modal__overlay')
    if (isOverlay)
      emit('close')
  }
}

onUnmounted(() => {
  if (document.body.classList.contains('delivery-time-modal-overflow-body'))
    document.body.classList.remove('delivery-time-modal-overflow-body')
})
</script>

<template>
  <transition
    :duration="{ enter: 400, leave: 0 }"
    enter-active-class="fade-in-up-enter-active"
    leave-active-class="fade-out-down-leave-active"
  >
    <div
      v-if="show"
      class="delivery-time-modal__overlay"
      @mousedown="onOverlayClick"
    >
      <div class="delivery-time-modal">
        <div class="delivery-time-modal__header">
          <div class="delivery-time-modal__title">
            {{ title }}
          </div>
          <div class="delivery-time-modal__close" @click="emit('close')" />
        </div>
        <div class="delivery-time-modal__wrap">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="postcss" scoped>
:global(body.delivery-time-modal-overflow-body) {
  overflow: hidden;
}

.delivery-time-modal {
  bottom: -85px;
  z-index: var(--z-above-modal);
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  padding: 15px 15px 15px;
  margin-top: auto;
  background-color: white;
  border: none;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.06);

  &__header {
    display: flex;
    justify-content: space-between
  }

  &__title {
    padding-bottom: 10px;
    font-size: 19px;
    font-weight: 500;
    line-height: 21px;
  }

  &__close {
    width: 11px;
    height: 11px;
    margin-top: 7px;
    cursor: pointer;
    background-image: svg-load('./asset/close.svg');
    background-repeat: no-repeat;
    background-size: 11px;
    transition: background-image 0.2s ease-out;

    &:hover {
      background-image: svg-load('./asset/close.svg', fill=#E30613);
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__overlay {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: calc(var(--z-above-modal) + 1);
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 1;
    will-change: transform, opacity
  }

  @media (min-width: 1000px) {
    max-width: 500px;
    padding: 24px;
    margin: auto;
    border-radius: 20px;

    &__title {
      padding-bottom: 24px;
      font-size: 24px;
      line-height: 28px;
    }

    &__close {
      width: 12px;
      height: 12px;
    }
  }
}
</style>
