<script setup lang="ts">
import { storeToRefs } from 'pinia'
import getMonthName from '~/helper/getMonthName'
import { useDeliveryTimeSelectModalStore } from '~/store/modal/deliveryTimeSelect'
import { useCheckoutStoreModal } from '~/store/modal/checkout'
import { useServiceStore } from '~/store/service'
import { useResizeStore } from '~/store/resize'
import { useCartStore } from '~/store/cart'
import { useOrderStore } from '~/store/order'
import checkCartChanges from '~/helper/checkCartChanges'

const props = withDefaults(defineProps<{
  noteShow?: boolean
  timeSelectModalButtonShow?: boolean
}>(), {
  noteShow: true,
  timeSelectModalButtonShow: true,
})

const deliveryTimeSelectModalStore = useDeliveryTimeSelectModalStore()
const { isWorkingHours } = storeToRefs(useServiceStore())
const checkoutModalStore = useCheckoutStoreModal()
const { isMobile } = storeToRefs(useResizeStore())
const cartStore = useCartStore()
const orderStore = useOrderStore()
const showConditionsModal = ref(false)
const delayedDeliveryPrice = computed(() => cartStore.deliveryTimeData?.delayed && [...cartStore.deliveryTimeData.delayed.pricing].reverse().find(p => p.fromOrderAmount <= cartStore.price.product)?.deliveryPrice)
const expressDeliveryPrice = computed(() => cartStore.deliveryTimeData?.express && [...cartStore.deliveryTimeData.express.pricing].reverse().find(p => p.fromOrderAmount <= cartStore.price.product)?.deliveryPrice)

const note = computed(() => {
  let noteText: string | undefined
  const currentType = orderStore.deliveryType && cartStore.deliveryTimeData[orderStore.deliveryType]
  if (currentType && props.noteShow) {
    const pricingItemIndex = currentType.pricing.findIndex(p => cartStore.price.product < p.fromOrderAmount)
    if (pricingItemIndex !== -1) {
      if (pricingItemIndex + 1 !== currentType.pricing.length)
        noteText = `Добавьте товары на ${currentType.pricing[pricingItemIndex].fromOrderAmount - cartStore.price.product}₽ и доставка станет ${currentType.pricing[pricingItemIndex].deliveryPrice}₽`
      else
        noteText = `Добавьте товары на ${currentType.pricing[pricingItemIndex].fromOrderAmount - cartStore.price.product}₽ и доставка станет бесплатной`
    }

    return noteText
  }
  else {
    return undefined
  }
})
const timeSelectButtonText = computed(() => {
  if (orderStore.deliveryDate) {
    const dayType = Number(orderStore.deliveryDate.date.day) === new Date().getDate() ? 'Сегодня' : 'Завтра'
    const month = getMonthName(orderStore.deliveryDate.date)
    return `${dayType} (${orderStore.deliveryDate.date.day} ${month}) c ${orderStore.deliveryDate.time.startTime} до ${orderStore.deliveryDate.time.endTime}`
  }
  else {
    return 'Укажите дату и время доставки'
  }
})

function onSetDeliveryType(type: 'express' | 'delayed') {
  const isTypeChanged = type !== orderStore.deliveryType
  if (isTypeChanged) {
    orderStore.setDeliveryType(type)
    orderStore.setDeliveryDate()
    checkCartChanges({ deliveryType: orderStore.deliveryType })
  }
}

function onTimeSelectClick() {
  if (isMobile.value)
    checkoutModalStore.disable()

  deliveryTimeSelectModalStore.enable()
}
</script>

<template>
  <div class="delivery-type" :class="{ 'delivery-type_margin': cartStore.deliveryTimeData.express && cartStore.deliveryTimeData.delayed }">
    <div class="delivery-type__buttons" :class="{ 'delivery-type__buttons_non-selected': !orderStore.deliveryType }">
      <button
        v-if="cartStore.deliveryTimeData.express && cartStore.serviceType !== 'off-hours'"
        class="delivery-type__button delivery-type__button_express"
        :class="{ 'delivery-type__button_selected': orderStore.deliveryType === 'express' }"
        @click="onSetDeliveryType('express')"
      >
        <img
          src="./asset/lightning.webp"
          alt="Экспресс"
          class="delivery-type__icon"
        >
        <div class="delivery-type__info">
          <div class="delivery-type__price">
            {{ expressDeliveryPrice ? `${expressDeliveryPrice}₽` : 'Бесплатная' }}
          </div>
          <div class="delivery-type__type">
            Экспресс (~{{ cartStore.deliveryMinutes }} мин)
          </div>
        </div>
      </button>
      <button
        v-if="cartStore.deliveryTimeData.delayed"
        class="delivery-type__button delivery-type__button_delayed"
        :class="{ 'delivery-type__button_selected': orderStore.deliveryType === 'delayed' }"
        @click="onSetDeliveryType('delayed')"
      >
        <img
          src="./asset/time.webp"
          alt="Выбрать слот"
          class="delivery-type__icon"
        >
        <div class="delivery-type__info">
          <div class="delivery-type__price">
            {{ delayedDeliveryPrice ? `${delayedDeliveryPrice}₽` : 'Бесплатная' }}
          </div>
          <div class="delivery-type__type">
            Доставим позже
          </div>
        </div>
      </button>
    </div>
    <CCDeliveryTypeNote
      v-if="note && orderStore.deliveryType && isWorkingHours && (cartStore.deliveryTimeData.delayed || cartStore.deliveryTimeData.express)"
      :note="note"
      :delivery-type="orderStore.deliveryType"
      class="delivery-type__note"
      @click-note="showConditionsModal = true"
    />
    <button
      v-if="timeSelectModalButtonShow && orderStore.deliveryType === 'delayed'"
      class="delivery-time-select-button"
      :class="{ 'delivery-time-select-button_expired': orderStore.timeExpired }"
      @click="onTimeSelectClick"
    >
      <span>{{ timeSelectButtonText }}</span>
      <img src="./asset/arrow-right-black.svg" alt="Указать время доставки">
    </button>
    <CCDeliveryTypeNote
      v-if="orderStore.timeExpired && !noteShow && orderStore.deliveryType === 'delayed'"
      note="Слот недоступен, выберите новый диапазон доставки"
      :delivery-type="orderStore.deliveryType"
      type="error"
    />
    <CCDeliveryTimeModal
      v-if="orderStore.deliveryType"
      :show="showConditionsModal"
      :title="orderStore.deliveryType === 'express' ? 'Экспресс доставка' : 'Доставка ко времени'"
      @close="showConditionsModal = false"
    >
      <template #default>
        <hr class="delivery-modal-content__line">
        <div class="delivery-modal-content__header">
          <div class="delivery-modal-content__title">
            {{ orderStore.deliveryType === 'express' ? 'Курьер приедет ~30 мин' : 'Курьер приедет в выбранный слот' }}
          </div>
          <div class="delivery-modal-content__worktime">
            Режим работы: до 21:00
          </div>
        </div>
        <div class="delivery-modal-content__ content">
          <div class="delivery-modal-content__conditions">
            Условия
          </div>
          <div class="delivery-modal-content__pricing">
            <div
              v-for="(item, i) in cartStore.deliveryTimeData[orderStore.deliveryType]?.pricing"
              :key="i"
              class="delivery-modal-content__pricing-item"
            >
              <div class="delivery-modal-content__start-block">
                <div class="delivery-modal-content__icon">
                  <img src="./asset/delivery-price.svg" alt="Условия доставки">
                </div>
                <span class="delivery-modal-content__delivery-price">
                  Доставка {{ item.deliveryPrice }}₽
                </span>
              </div>
              <span class="delivery-modal-content__order-price">
                от {{ item.fromOrderAmount }}₽
              </span>
            </div>
          </div>
          <button class="delivery-modal-content__button" @click="showConditionsModal = false">
            Хорошо
          </button>
        </div>
      </template>
    </CCDeliveryTimeModal>
  </div>
</template>

<style lang="postcss" scoped>
.delivery-type {
  display: flex;
  flex-direction: column;

  &_margin {

    .delivery-type__button:first-child {
      margin-right: 8px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 8px;
    cursor: pointer;
    background: #F8F8F8;
    background-color: #fff;
    border: none;
    border-radius: 20px;
    opacity: 0.6;

    &_selected {
      background-color: #F8F8F8;
      opacity: 1;
    }
  }

  &__buttons {
    display: flex;

    &_non-selected {
      margin-bottom: 20px;
    }
  }

  &__price {
    margin-bottom: 2px;
  }

  &__type {
    font-size: 12px;
    line-height: 14px;
    color: #818181;
    text-align: start;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__icon {
    width: 32px;
    margin-right: 6px;
  }

  @media (min-width: 1000px) {

    &__note {
      margin-bottom: 15px;
    }
  }
}

.delivery-time-select-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 15px;
  margin-top: 10px;
  color: #007AFF;
  cursor: pointer;
  background-color: #f8f8f8;
  border: none;
  border-radius: 20px;

  &_expired {
    color: #00B0B8;
  }
}

.delivery-modal-content {

  &__button {
    width: 100%;
    padding: 17px 14px;
    color: #fff;
    cursor: pointer;
    background-color: #00B0B8;
    border: none;
    border-radius: 12px;
  }

  &__icon {
    padding: 12px;
    margin-right: 6px;
    background-color: #F9F9F9;
    border-radius: 100%;
  }

  &__start-block {
    display: flex;
    align-items: center;
  }

  &__pricing-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    &:last-child {
      margin: 0
    }
  }

  &__pricing {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  &__conditions {
    margin-bottom: 10px;
    color: #818181;
  }

  &__worktime {
    color: #818181;
  }

  &__title {
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 18px;
  }

  &__line {
    width: 100$;
    height: 1px;
    color: #F3F3F3;
    opacity: 0.2;
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    margin: 15px 0;
    background-color: #F9F9F9;
    border-radius: 20px;
  }
}
</style>
