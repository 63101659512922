<script lang="ts" setup>
import { formatPhone } from '~/helper/formatPhone'

interface Props {
  modelValue: string
  error?: boolean
  success?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  error: false,
  success: false,
})

const emit = defineEmits<{
  (e: 'update:model-value', value: string): void
  (e: 'blur', value: string): void
  (e: 'submit'): void
}>()

const currentValue = ref(props.modelValue)
const root = shallowRef<HTMLInputElement>()

defineExpose({
  root,
})

function onInput(e: Event) {
  if (e.target instanceof HTMLInputElement) {
    currentValue.value = formatPhone(e.target.value)
    root.value!.value = currentValue.value
    emit('update:model-value', currentValue.value)
  }
}

function onBlur(e: Event) {
  if (e.target instanceof HTMLInputElement)
    emit('blur', e.target.value)
}

function handleKeyPress(e: KeyboardEvent) {
  if (e.key === 'Enter')
    emit('submit')

  if (e.key === 'Backspace')
    return

  if (currentValue.value.length > 15)
    e.preventDefault()
}
</script>

<template>
  <label class="input-phone">
    <input
      ref="root"
      type="tel"
      :value="currentValue"
      class="input-phone__input"
      @input="onInput"
      @blur="onBlur"
      @keydown="handleKeyPress"
    >
  </label>
</template>

<style lang="postcss" scoped>
.input-phone {

  &__input {
    box-sizing: border-box;
    width: 100%;
    height: 39px;
    margin-bottom: 3px;
    font-size: 28px;
    line-height: 32px;
    color: #000;
    letter-spacing: 5px;
    appearance: none;
    background-color: transparent;
    background-image: url(./asset/input-phone-mask-bg.svg);
    background-repeat: no-repeat;
    background-position: bottom left;
    border: none;
    outline: none;

    &:-webkit-autofill {
      font-size: 14px;
      line-height: 16px;
      -webkit-box-shadow: 0 0 0 50px #fffdf4 inset;
    }

    &::placeholder {
      color: #000;
      letter-spacing: 5.6px;
    }

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }
}
</style>
