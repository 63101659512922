<script setup lang="ts">
import { useServiceStore } from '~/store/service'

defineProps<{
  type: 'unavailable' | 'off-hours' | 'no-delivery'
  page: 'main' | 'catalog'
}>()
const serviceStore = useServiceStore()

const factoidData = {
  'unavailable': {
    title: 'Режим просмотра',
    description: 'Временно заказы не принимаем, пока ознакомьтесь с ассортиментом',
  },
  'off-hours': {
    title: 'Закажите заранее',
    description: 'Пока мы не можем работать круглосуточно, но обязательно этому научимся. Вы можете сейчас оформить заказ, завтра доставим!',
  },
  'no-delivery': {
    title: 'Сейчас закрыты',
    description: 'Мы работаем каждый день c ',
    timeText: `${serviceStore.schedule.from || '09:00'} - ${serviceStore.schedule.to || '21:00'}`,
  },
}
</script>

<template>
  <div :class="`service-factoid service-factoid_${type} service-factoid_${page}` ">
    <div class="service-factoid__title">
      {{ factoidData[type].title }}
    </div>
    <div class="service-factoid__description">
      {{ factoidData[type].description }} <span
        v-if="type === 'no-delivery'"
        class="service-factoid__time-text"
      >{{ factoidData[type].timeText }}</span>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.service-factoid {
  padding: 15px;
  margin: 0 -15px;
  overflow: hidden;
  border-radius: 0 0 20px 20px;

  &_catalog {
    background-color: #F8F8F8;
  }

  &_main {
    background-color: #FFF;
  }

  &_off-hours {
    background-image: url('./asset/clock.webp');
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) 54%;
    background-size: 71px;

    .service-factoid__title, .service-factoid__description {
      max-width: 188px;
    }
  }

  &_no-delivery {
    background-image: url('./asset/zzz.webp'), url('./asset/cat.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: calc(100% - 68px) 46%, calc(100% + 57px) -41%;
    background-size: 64px 40px, 174px 111px;

    .service-factoid__title, .service-factoid__description {
      max-width: 147px;
    }
  }

  &_unavailable {
    background-image: url('./asset/cat2.svg');
    background-repeat: no-repeat;
    background-position: calc(100% + 28px) 173%;
    background-size: 105px 101px;

    .service-factoid__title, .service-factoid__description {
      max-width: 207px;
    }
  }

  &__time-text {
    color: #00B0B8;
  }

  &__title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: #00B0B8;
  }

  &__description {
    font-size: 14px;
    line-height: 20px;
    color: #8E8E8E;
  }

  @media (min-width: 1000px) {
    width: 238px;
    margin: 0;
    background-color: #F8F8F8;
    border-radius: 20px;

    &_off-hours, &_no-delivery, &_unavailable {
      background-image: none;
    }
  }

  @media (min-width: 1000px) and (max-width: 1300px) {
    width: 180px;
  }
}
</style>
