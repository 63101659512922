import { isWithinInterval } from 'date-fns'
import { defineStore } from 'pinia'
import setTimeToDate from '~/helper/setTimeToDate'

export const useServiceStore = defineStore('service', () => {
  const serviceUnavailable = ref(false)
  const apiUnavailable = ref(false)

  /**
* Дата в формате "M/DD/YYYY, HH:MM:SS AM/PM" для 'Europe/Moscow'.
* Хранение строки обеспечивает корректное отображение времени работы Близко независимо от клиентской таймзоны.
*/
  const currentDate = ref<null | string>(null)
  const showDeliveryDelayNotification = ref(false)
  const schedule = ref({
    from: '09:00',
    to: '21:00',
  })
  const traceParent = ref<string>()

  function setCurrentDate(payload: string) {
    currentDate.value = payload
  }

  function setServiceUnavailable(state: boolean) {
    serviceUnavailable.value = state
  }

  function setApiUnavailable(state: boolean) {
    apiUnavailable.value = state
  }

  function setTraceParent(val: string) {
    traceParent.value = val
  }

  function setShowDeliveryDelayNotification() {
    showDeliveryDelayNotification.value = true
    setTimeout(() => {
      showDeliveryDelayNotification.value = false
    }, 10000)
  }

  const isWorkingHours = computed(() => {
    if (currentDate.value) {
      const date = new Date(currentDate.value)

      const startTime = setTimeToDate(schedule.value.from, date)
      const endTime = setTimeToDate(schedule.value.to, date)

      const isInterval = isWithinInterval(date, { start: startTime, end: endTime })
      if (!isInterval || serviceUnavailable.value)
        return false
      else return true
    }
    else {
      return false
    }
  })

  const isDeliveryDelayed = computed(() => {
    if (currentDate.value) {
      const date = new Date(currentDate.value)
      const startTime = setTimeToDate('12:00', date)
      const endTime = setTimeToDate('13:00', date)
      const isInterval = isWithinInterval(date, { start: startTime, end: endTime })
      return isInterval && date.getDay() === 5
    }
  })

  return {
    currentDate,
    schedule,
    isWorkingHours,
    traceParent,
    setTraceParent,
    setCurrentDate,
    setServiceUnavailable,
    setApiUnavailable,
    serviceUnavailable,
    apiUnavailable,
    isDeliveryDelayed,
    showDeliveryDelayNotification,
    setShowDeliveryDelayNotification,
  }
})
