import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { DeliveryOrderDate, DeliveryType } from '~/type/DeliveryTime'
import type { Order } from '~/type/order/Order'

export const useOrderStore = defineStore('order', () => {
  const deliveryDate = ref<DeliveryOrderDate>()
  const deliveryType = ref<DeliveryType | undefined>('express')
  const timeExpired = ref(false)
  const orders = ref<Order[]>([])
  function setDeliveryDate(date?: DeliveryOrderDate) {
    deliveryDate.value = date ?? undefined
  }

  function setDeliveryType(type?: DeliveryType) {
    deliveryType.value = type ?? undefined
  }

  function setExpiredDateValue(state: boolean) {
    timeExpired.value = state
  }

  function setOrderList(data: Order[]) {
    orders.value = data
  }

  return { deliveryDate, deliveryType, timeExpired, orders, setDeliveryDate, setDeliveryType, setExpiredDateValue, setOrderList }
}, {
  persist: true,
  share: {
    enable: true,
  },
})
