import { checkDeliveryType } from './checkDeliveryType'
import { useCartStore } from '~/store/cart'
import { useCartChangedModal } from '~/store/modal/cartChanged'
import type { DeliveryType } from '~/type/DeliveryTime'

/*
  Определение изменений в корзине и оповещение об этом
*/
export default async function (params?: { deliveryType?: DeliveryType }) {
  const { $api, $error } = useNuxtApp()
  const cartStore = useCartStore()
  const cartChangedModalStore = useCartChangedModal()

  try {
    if (cartChangedModalStore.isChecked) {
      return false
    }
    else {
      const data = await $api.order.getOrderData({ deliveryType: params?.deliveryType })
      cartStore.setCart(data)
      checkDeliveryType()

      if (cartStore.cartChanged)
        cartChangedModalStore.enable()

      return cartStore.cartChanged
    }
  }

  catch (e) {
    new $error.simple({
      name: 'Ошибка',
      description: 'Ошибка при получении корзины, попробуйте позже',
      message: 'Ошибка при получении корзины',
    })
  }
}
